import React, { useEffect, useState, useRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '../../ui/core'
import ServiceQuestions from '../../../services/question'
import ServiceRespondents from '../../../services/respondents'
import ServiceSurvey from '../../../services/survey'
import ServiceGeneral from '../../../services/general'
import ServiceExcel from '../../../services/excel';
import { ChevronLeft, ChevronRight } from '../../ui/coreIcons'
import useWindowSize from '../../../hooks/windowsize';
export default function Info({ survey, onClose }) {
    const refScroll = useRef();
    const windowsize = useWindowSize();
    const [questions, setQuestions] = useState([])
    const [respondents, setRespondents] = useState([])
    const [results, setResults] = useState({})
    const headerStyle = { backgroundColor: 'rgb(0, 105, 143)', color: 'rgb(255, 255, 255)' }
    useEffect(() => {
        if (survey) {
            ServiceQuestions.getForSurvey(survey._id)
                .then(result => {
                    result = result.sort((a, b) => { return a.sort - b.sort })
                    setQuestions(result)
                })

            ServiceRespondents.getForSurvey(survey._id)
                .then(result => { setRespondents(result) })

            ServiceSurvey.getScoresDetail(survey._id)
                .then(result => { setResults(result) })

        }
    }, [survey])

    const getHeaderRespondents = () => {
        return respondents.map(res => { return <th style={headerStyle} colSpan={3} key={res._id + '_header_name'}>{res.name}</th> })
    }

    const getHeaderRespondents2 = () => {
        return respondents.map(respondent => {
            let tmpCells = [];
            tmpCells.push(<th style={headerStyle} key={respondent._id + '_header_score'}>Score</th>)
            tmpCells.push(<th style={headerStyle} key={respondent._id + '_header_waarneming'}>Toelichting</th>)
            tmpCells.push(<th style={headerStyle} key={respondent._id + '_header_document'}>Documenten</th>)
            return tmpCells
        })
    }

    const findScore = (question, respondent, type) => {
        if (type == 3 || type == 4) {
            if (results.closedValues) {
                let found = results.closedValues.filter(val => { return (val.question === question._id && val.respondent === respondent) })
                if (found && found.length > 0) {
                   // console.log(question)
                    //  return found.map(a => {if( a.value ===-1){return 'N.v.t.'}else{ return a.value + ' / ' + ServiceGeneral.roundNumber(a.perc) + '%' }}).join();
                    //  console.log(found)
                    let textNVT = "N.v.t.";
                    try{ 
                        textNVT=  question.options.find(opt => { return opt.score == -1 }).text;
                    }catch(e){console.log(e)}
                  
                    return found.map(a => { if (a.value === -1) { return textNVT } else { return a.value + ' / ' + ServiceGeneral.roundNumber(a.perc) + '%' } }).join();

                } else {
                    return '-'
                }
            } else {
                return '..'
            }
        } else {
            if (results.openValues) {
                let found = results.openValues.filter(val => { return (val.question === question._id && val.respondent === respondent) })
                if (found && found.length > 0) {
                    return found.map(a => { return a.value }).join();
                } else {
                    return '-'
                }
            } else {
                return '..'
            }
        }
    }
    const findWaarneming = (question, respondent) => {
        if (results.openText) {
            let found = results.openText.filter(val => { return (val.question === question && val.respondent === respondent) })
            if (found && found.length > 0) {
                return found.map(a => { return a.openText }).join();
            } else {
                return '-'
            }
        } else {
            return '..'
        }

    }

    const getScoreBackColor = (question, respondent) => {
        if (results.closedValues) {
            let found = results.closedValues.find(val => { return (val.question === question && val.respondent === respondent) })
            if (found) {
                let color = ServiceSurvey.getColorFromRange(survey.colorRange, ServiceGeneral.roundNumber(found.perc));
                return color;
            }
        }
        return 'transparent'
    }

    const getScoresRespondent = (question, respondent) => {
        let tmpCells = [];
        const backgroundColor = getScoreBackColor(question._id, respondent._id);
        tmpCells.push(<td key={respondent._id + '_' + question._id + '_score'} style={{ backgroundColor: backgroundColor, whiteSpace: 'nowrap' }}>{findScore(question, respondent._id, question.type)}</td>)
        tmpCells.push(<td key={respondent._id + '_' + question._id + '_waarneming'}>{findWaarneming(question._id, respondent._id)}</td>)
        tmpCells.push(<td key={respondent._id + '_' + question._id + '_document'}>-</td>)
        return tmpCells
    }

    const getScores = () => {
        return questions.map(quest => {
            return <tr key={quest._id + '_row'}><td>{quest.question || quest.text}</td>{respondents.map(res => { return getScoresRespondent(quest, res) })}</tr>
        })
    }

    const download = () => {
        ServiceExcel.downloadHTMLTable(document.getElementById('tblDetailReport'))
    }

    return (
        <Dialog fullScreen={true}>
            <DialogTitle ><Typography component="h1">Gedetailleerd rapportage</Typography></DialogTitle>
            <DialogContent>
                <div ref={refScroll} style={{ position: 'relative', width: windowsize.innerWidth, overflowX: 'scroll' }}>
                    <table id="tblDetailReport" className="ui-table">
                        <tbody>
                            {/*HEADER */}
                            <tr><th style={headerStyle}></th>{getHeaderRespondents()}</tr>
                            <tr><th style={headerStyle}></th>{getHeaderRespondents2()}</tr>
                            {getScores()}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <div style={{ float: 'left' }}>
                    <Button variant="outlined" onClick={() => { refScroll.current.scroll({ left: (refScroll.current.scrollLeft - 200 < 0 ? 0 : refScroll.current.scrollLeft - 200), behavior: 'smooth' }) }} ><ChevronLeft /></Button>
                    <Button variant="outlined" onClick={() => { refScroll.current.scroll({ left: refScroll.current.scrollLeft + 200, behavior: 'smooth' }) }}><ChevronRight /></Button>
                </div>
                <Button onClick={download}>Download Excel</Button>
                <Button onClick={onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
