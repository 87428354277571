import React, { useContext, useEffect, useState } from 'react';
import Service from '../../services/evaluations';
import GeneralService from '../../services/general';
import ServiceUser from '../../services/user';
import ServiceDepartments from '../../services/department';
import { Context } from '../../AppContext';
import { ContextUI } from '../../uiContext';
import { IconButton, Select, Typography } from '../../components/ui/core';
import { Add, Help } from '../../components/ui/coreIcons';
import List from '../../components/module-evaluation/list';
import { SearchBar, Paper, Tabs, Menu, MenuItem } from '../../components/ui/core';
import Monitor from '../../components/module-evaluation/monitor';
import MainMenu from '../../components/mainmenu';
import Footer from '../../components/mainfooter';
export default function Index(props) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [hasRights, setHasRights] = useState(false);
    const getSelectedTab = () => {
        if (localStorage.getItem('evaluation-selected-tab')) {
            return parseInt(localStorage.getItem('evaluation-selected-tab'));
        } else {
            return 0;
        }
    }
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [selectedTab, setSelectedTab] = useState(getSelectedTab())
    const [search, setSearch] = useState('');
    const [departments, setDepartments] = useState([])

    const [newEvaluationMenu, setNewEvaluationMenu] = useState(null);

    useEffect(() => {
        localStorage.setItem('evaluation-selected-tab', selectedTab)
    }, [selectedTab])

    useEffect(() => {
        if (context.organisation) {
            ServiceDepartments.getForOrganisation(context.organisation._id)
                .then(results => {

                    setDepartments(results);

                })
        }
    }, [context.organisation])

    useEffect(() => {
        contextUI.setShowHeader(false);
        //     contextUI.setShowHeader(true);
        //     contextUI.setSubTitle('Dashboard');
        //     contextUI.setShowBackButton(false);

        if (context.organisation) {
            getItems();


            let menuItems = [];
            let rights = false;
            //menu.push();
            menuItems.push(<MenuItem >Toevoegen evaluatie voor:</MenuItem>);
            if (ServiceUser.canWrite(context.user.rights, 'organisations-evaluation', context.organisation._id)) {
                menuItems.push(<MenuItem onClick={() => { addItem() }}>{context.organisation.name}</MenuItem>);
                rights = true;
                menuItems.push(<hr />)
            }

            for (let d = 0; d < departments.length; d++) {
                if (ServiceUser.canWrite(context.user.rights, 'departments-evaluation', departments[d]._id)) {
                    menuItems.push(<MenuItem onClick={() => { addItem(departments[d]._id) }}>{departments[d].name}</MenuItem>);
                    rights = true;
                }
            }
            setHasRights(rights);
            let menu;
            if (rights) menu = <Select >{menuItems}   </Select>
            setNewEvaluationMenu(menu);
            //   contextUI.setHeaderActions(<>{menu}<IconButton onClick={() => context.setShowFAQ(0)}><Help color="white" /></IconButton></>)


        }
    }, [context.organisation, departments])

    //GETTING ITEMS FROM API
    const getItems = () => { Service.getForOrganisation(context.organisation._id).then(items => setItems(GeneralService.sortJSON(items, 'created', '321'))).catch(err => { console.log(err) }) }

    const addItem = (department) => {
        
        Service.insert({ organisation: context.organisation._id, department: department, title: 'Nieuwe evaluatie' }).then(item => { viewItem(item._id); }).catch(ex => { console.log(ex) })
    }

    const viewItem = (id) => { props.history.push('/evaluatie/' + id) }

    useEffect(() => {
        setItemsFiltered(items.filter(i => { return JSON.stringify(i).toLowerCase().indexOf(search.toLowerCase()) >= 0 }));
    }, [search])

    useEffect(() => {
        setItemsFiltered(items);
        setSearch('');
    }, [items])


    return (
        < >
            <MainMenu history={props.history} />
            <div style={{ minHeight: '800px' }}>
                <Paper>
                    <Tabs value={selectedTab} onChange={setSelectedTab} >
                        <div className="ui-tab">ACTUELE EVALUATIES</div>
                        <div className="ui-tab">ARCHIEF</div>
                    </Tabs>
                </Paper>

                {(selectedTab === 0 || selectedTab === 1) && context.organisation && <>
                    <SearchBar value={search} onKeyUp={setSearch} onReset={() => { setSearch('') }}></SearchBar>
<div style={{display:'flex',flexWrap:'wrap'}}>


                    {hasRights && <div onClick={() => { }} className="card" style={{ border: '2px dashed silver' }}>
                        <div className="card-content" style={{ textAlign: 'center', fontSize: '70px', color: 'gray', marginTop: '120px' }}>
                            <Typography component="h3">Evaluatie toevoegen</Typography>

                            <select onChange={(ev) => { addItem(ev.target.value) }}>
                                <option>Selecteer locatie</option>
                                {(ServiceUser.canWrite(context.user.rights, 'organisations-evaluation', context.organisation._id)) &&
                                    <option value="">{context.organisation.name}</option>
                                }
                                { 
                                    departments.map((department) => {
                                       if(ServiceUser.canWrite(context.user.rights, 'departments-evaluation', department._id) ){
                                        return (
                                            <option key={department._id} value={department._id}>{department.name}</option>
                                        )
                                       }else{
                                        return null;
                                       }
                                       
                                    })
                                }
                            </select>
                            <br /><br /><div>+</div>
                        </div>
                    </div>}
               
                    <List items={itemsFiltered.filter(item => { if (selectedTab === 1 && item.archived === true) { return true }; if (selectedTab === 0 && !item.archived) { return true }; return false; })} onClick={(item) => { props.history.push('/evaluatie/' + item._id) }} />
                   
                    </div>
                </>}
            </div>
            <Footer />
        </>
    )
}
