import React from 'react'
//todo create tooltip
const  Tooltip = (props)=> {
    //props.title
    //props.placement  : top ; bottom
    return (
        <div className="tooltip">
            {props.children}
            {props.label && <span className="tooltiptext">{props.label||''}</span>}

        </div>
    )
}
export { Tooltip };