import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './ui.css';
import './index.css';
import './charting.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//veditor global scripts
import './components/veditor/scripts';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(  <App />);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
