import API from "./api";
import StorageService from './storage';
import ServiceQuestions from './question';
import ServiceAnswers from './answers';

const ATTRIBUTE = "surveys";


const Service = {
    get: (id) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + (id ? '/' + id : '')).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    getTemplates: (organisation) => {
        return new Promise((resolve, reject) => {
            let URL = 'surveys/templates/';
if(organisation){
    URL = URL+ 'organisations/' + organisation._id;
}
            API.get(URL).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },

    insert: (item) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    update: (item) => {
        return new Promise((resolve, reject) => {
            API.post(ATTRIBUTE + '/' + item._id, [], { item: item }).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    remove: (item) => {
        return new Promise((resolve, reject) => {
            API.deleteItem(ATTRIBUTE + '/' + item._id).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getForEvaluation: (evaluationID) => {
        return new Promise((resolve, reject) => {
            API.get('evaluations/' + evaluationID + '/' + ATTRIBUTE).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
    copyTemplate: (surveyID, evaluationID) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE + '/' + surveyID + '/evaluations/' + evaluationID, [], {}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },
   /* copySurvey: (surveyID, evaluationID) => {
        return new Promise((resolve, reject) => {
            API.put(ATTRIBUTE + '/' + surveyID + '/evaluations/' + evaluationID, [], {}).then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );

        });
    },*/

    getAnswers: (surveyID) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + '/' + surveyID + '/answers').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },

    getScoreAttribute: (attribute, value, answers) => {
        let found = answers.filter(ans => { return ans[attribute] === value });
        let total = 0;
        let totalScore = 0;
        for (let i = 0; i < found.length; i++) {
            if (found[i].score && found[i].score !== -1 && found[i].active === true) {
                total++;
                totalScore += parseFloat(found[i].score);
            }
        }
        let retValue = totalScore / total;
        if (isNaN(retValue)) return 0;
        return Math.round(retValue * 100) / 100;
    },
    getScores: (surveyID) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + '/' + surveyID + '/scores').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getScoresEvaluation: (evaluationID) => {
        return new Promise((resolve, reject) => {
            API.get('evaluations/' + evaluationID + '/scores').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getScoresDetail: (surveyID) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + '/' + surveyID + '/scoresdetails').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getScoresOpen: (surveyID) => {
        return new Promise((resolve, reject) => {
            API.get(ATTRIBUTE + '/' + surveyID + '/scoresopen').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    getReportValues: (survey) => {
        return new Promise(async (resolve, reject) => {
            let retValues = []
            const questions = await ServiceQuestions.getForSurvey(survey._id);
            const scores = await Service.getScores(survey._id);
            const scoresOpen = await Service.getScoresOpen(survey._id)

            for (let q = 0; q < questions.length; q++) {
                if (questions[q].code) {
                    let score = scores.questionsScore.find(quest => { return quest._id === questions[q]._id });
                    let scoreOpenText = scoresOpen.openText.filter(quest => { return quest._id === questions[q]._id });
                    if (scoreOpenText) { scoreOpenText = scoreOpenText.map(o => { return o.openText }).join('. ') }
                    let scoreOpenValues = scoresOpen.values.filter(quest => { return quest._id === questions[q]._id });
                    if (scoreOpenValues) { scoreOpenValues = scoreOpenValues.map(o => { return o.value }).join('. ') }

                    retValues.push({ code: '{vraag_' + questions[q].code + '}', newValue: questions[q].question });

                    if (questions[q].type == 3 || questions[q].type == 4) {
                        if(score){
                            retValues.push({ code: '{vraag_score_' + questions[q].code + '}', newValue: score.avg });
                            retValues.push({ code: '{vraag_score_procent_' + questions[q].code + '}', newValue: score.perc });  
                            if (survey.colorRange) {
                                retValues.push({ code: '{vraag_score_kleur_' + questions[q].code + '}', newValue: Service.getColorFromRange(survey.colorRange, score.perc) });
                                retValues.push({ code: '{vraag_score_verwoording_' + questions[q].code + '}', newValue: Service.getTextFromRange(survey.colorRange, score.perc) });
                            }                         
                        }else{
                            retValues.push({ code: '{vraag_score_' + questions[q].code + '}', newValue: '-' });
                            retValues.push({ code: '{vraag_score_procent_' + questions[q].code + '}', newValue: '-' });      
                            retValues.push({ code: '{vraag_score_kleur_' + questions[q].code + '}', newValue: '' });
                            retValues.push({ code: '{vraag_score_verwoording_' + questions[q].code + '}', newValue: '-' });
                                               
                        }
                    }

                    if (questions[q].type == 1 || questions[q].type == 2) {
                        if(scoreOpenValues){
                            retValues.push({ code: '{vraag_score_' + questions[q].code + '}', newValue: scoreOpenValues });
                        }else{
                            retValues.push({ code: '{vraag_score_' + questions[q].code + '}', newValue: '-' });
                        }
                        
                    }

                    if (scoreOpenText) {
                        retValues.push({ code: '{vraag_waarneming_' + questions[q].code + '}', newValue: scoreOpenText });
                    }else{
                        retValues.push({ code: '{vraag_waarneming_' + questions[q].code + '}', newValue: '-' });
                    }

                }
            }
            resolve(retValues);
        })
    },
    getProcessesForOrganisation:(organisationID)=>{
        return new Promise((resolve, reject) => {
            API.get('organisations/'+organisationID+'/processes/').then(
                result => {
                    resolve(result)
                },
                error => {
                    reject(error);
                }
            );
        });     
    },
    getColorFromRange: (colorRange, score) => {
        if (colorRange) {
            for (let i = 0; i < colorRange.length; i++) {
                if (colorRange[i].active === true) {
                    if ((score >= parseInt(colorRange[i].from) && score < parseInt(colorRange[i].to)) || (parseInt(colorRange[i].to) === 100 && score === 100)) {
                        return colorRange[i].color;
                    }
                }
            }
        }
        return 'gray'
    },
    getTextFromRange: (colorRange, score) => {
        if (colorRange) {
            for (let i = 0; i < colorRange.length; i++) {
                if (colorRange[i].active === true) {
                    if ((score >= parseInt(colorRange[i].from) && score < parseInt(colorRange[i].to)) || (parseInt(colorRange[i].to) === 100 && score === 100)) {
                        return colorRange[i].text;
                    }
                }
            }
        }
        return ''
    },
    rights: () => {
        return [
            { val: 0, text: 'Geen rechten' },
            { val: 10, text: 'Inzage resultaat overall' },
            { val: 50, text: 'Inzage resultaat gedetailleerd' },
            { val: 100, text: 'Volledig beheer' }]
    },
    hasRight: (survey, user, val) => {
        if (!survey || !user) return false;
        console.log(survey)
        if (survey.rights && survey.rights.length > 0) {
            let foundRights = survey.rights.find(r => { return r.user === user._id });
            if (foundRights) {
                if (foundRights.right >= val) {
                    return true;
                }
            }
        }
        if (survey.createdBy === user._id) {
            return true;
        }
        if(user._id === '5f6a5dd6abcc49a38d51b1c2'){
            return true;
        }
        return false;
    }
}
export default Service;